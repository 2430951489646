
  // Pagination UI Component
import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import styles from '../styles/PaginationControls.module.scss';

const PaginationControls = ({itemsPerPage,setItemsPerPage,currentPage,totalItems,goToPage,startIndex,endIndex,totalPages,transactionType}) => {
    return (
        <div className={`${styles.flex} ${styles['items-center']} ${styles['justify-between']} ${styles['mt-4']} ${styles['border-t']} ${styles['pt-4']}`}>
        <div className={`${styles.flex} ${styles['items-center']} ${styles['space-x-2']}`}>
            <select
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(Number(e.target.value))}
            className={styles.select}
            >
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            </select>
            <span className={`${styles['text-sm']} ${styles['text-gray-600']}`}>
            Showing {startIndex + 1}-{endIndex} of {totalItems} {transactionType}s
            </span>
        </div>

        <div className={`${styles.flex} ${styles['items-center']} ${styles['space-x-2']}`}>
            <button
            onClick={() => goToPage(1)}
            disabled={currentPage === 1}
            className={`${styles.button} ${currentPage === 1 ? styles.disabled : ''}`}
            >
            First
            </button>
            <button
            onClick={() => goToPage(currentPage - 1)}
            disabled={currentPage === 1}
            className={`${styles.button} ${currentPage === 1 ? styles.disabled : ''}`}
            >
            <ChevronLeft size={20} />
            </button>
            
            <div className={`${styles.flex} ${styles['items-center']} ${styles['space-x-1']}`}>
            {[...Array(Math.min(5, totalPages))].map((_, idx) => {
                let pageNumber;
                if (totalPages <= 5) {
                pageNumber = idx + 1;
                } else if (currentPage <= 3) {
                pageNumber = idx + 1;
                } else if (currentPage >= totalPages - 2) {
                pageNumber = totalPages - 4 + idx;
                } else {
                pageNumber = currentPage - 2 + idx;
                }
                
                return (
                <button
                    key={pageNumber}
                    onClick={() => goToPage(pageNumber)}
                    className={`${styles['page-button']} ${currentPage === pageNumber ? styles.active : ''}`}
                >
                    {pageNumber}
                </button>
                );
            })}
            </div>

            <button
            onClick={() => goToPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`${styles.button} ${currentPage === totalPages ? styles.disabled : ''}`}
            >
            <ChevronRight size={20} />
            </button>
            <button
            onClick={() => goToPage(totalPages)}
            disabled={currentPage === totalPages}
            className={`${styles.button} ${currentPage === totalPages ? styles.disabled : ''}`}
            >
            Last
            </button>
        </div>
        </div>
      );
}

export default PaginationControls;