import React, { useState, useEffect } from "react";

import "../styles/sidebar.scss";

import { useAuth } from "../utils/AuthContext";

// import walletIcon from '../assets/wallet.png';
// import transactionIcon from '../assets/transaction.png';
// import dashboardIcon from '../assets/dashboard.png';

import styles from "../styles/Sidebar.module.scss";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchExpenses,
  addNewExpense,
  syncExpenses,
  setOnlineStatus,
} from "../redux/expense/ExpenseSlice";
import { AlertCircle, DollarSign, Wifi, WifiOff, Trash2 } from "lucide-react";
const ExpenseSidebar = ({ isOpen, toggleSidebar }) => {
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns 0-based value
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const API_URL = "https://api.smart-spending.com/expense";
  const CATEGORIES_URL = "https://api.smart-spending.com/categories";

  const { isOnline = navigator.onLine } = useSelector(
    (state) => state.expense || {}
  );

  const dispatch = useDispatch();

  // const [expenseType,setExpenseType] = useState(); `

  const { getToken } = useAuth();
  const [expense, setExpense] = useState({
    amount: "",
    description: "",
    date: getTodayDate(),
    payment_method: "",
    is_recurring: false,
    recurrence_frequency: null,
    comments: "",
    category_id: "",
    type: "Income",
  });
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedType, setSelectedType] = useState('Income');

  // useEffect(() => {
  //   const fetchCategories = async () => {
  //     try {
  //       const token = getToken();
  //       if (!token) {
  //         throw new Error("Not authenticated");
  //       }

  //       const response = await fetch(CATEGORIES_URL, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });

  //       if (!response.ok) {
  //         throw new Error("Failed to fetch categories");
  //       }

  //       const data = await response.json();
  //       setCategories(data);
  //     } catch (err) {
  //       setError("Failed to load categories. Please try again later.");
  //     }
  //   };

  //   fetchCategories();
  // }, [getToken]);

  // Update the type in expense when a button is clicked
  const handleTypeChange = (type) => {
    setExpense((prev) => ({
      ...prev,
      type: type,
    }));
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setExpense((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    setSuccess(false);

    // try {
    //   const token = getToken();
    //   if (!token) {
    //     throw new Error('Not authenticated');
    //   }

    //   const response = await fetch(API_URL, {
    //     method: 'POST',
    //     headers: {
    //       'Authorization': `Bearer ${token}`,
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(expense),
    //   });

    //   if (!response.ok) {
    //     throw new Error('Failed to submit expense');
    //   }

    //   const data = await response.json();
    //   setSuccess(true);
    //   setExpense({
    //     amount: '',
    //     description: '',
    //     date: '',
    //     payment_method: '',
    //     is_recurring: false,
    //     recurrence_frequency: null,
    //     location: '',
    //     category_id: '',
    //   });
    // } catch (err) {
    //   setError(err.message || 'An error occurred while submitting the expense.');
    // } finally {
    //   setIsLoading(false);
    // }

    try {
      if (expense.amount && expense.date) {
        await dispatch(addNewExpense(expense));

        if (!isOnline) {
          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 3000);
        }

        setExpense(curr=>({
          amount: "",
          description: "",
          date: "",
          payment_method: "",
          is_recurring: false,
          recurrence_frequency: null,
          comments: "",
          category_id: "",
          type: curr.type,
        }));
      }
    } catch (err) {
      setError(
        err.message || "An error occurred while submitting the expense."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <button
        className={`toggle-button ${isOpen ? "open" : "closed"}`}
        onClick={toggleSidebar}
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 24 24"
          className={`menu-icon ${isOpen ? "open" : "closed"}`}
        >
          <line x1="3" y1="6" x2="21" y2="6" />
          <line x1="3" y1="12" x2="21" y2="12" />
        </svg>
      </button>
      <section className={`sidebar ${isOpen ? "open" : "closed"}`}>
        {/* <div className="sidebar-content">
          <button className={`${isOpen ? "active":""}`}><img className='active' src={dashboardIcon} alt='icon'/> {isOpen ? <span>Dashboard</span> : ''}</button>
          <button><img src={walletIcon} alt='icon'/> {isOpen ? <span>My Wallet</span> : ''}</button>
          <button><img src={transactionIcon} alt='icon'/> {isOpen ? <span>Transactions</span> : ''}</button>
        </div> */}

        {isOpen ? (
          <div className={styles.formContainer}>
            <h2 className={styles.formTitle}>Add New Transaction</h2>
            {error && <p className={styles.errorMessage}>{error}</p>}
            {success && (
              <p className={styles.successMessage}>
                Expense added successfully!
              </p>
            )}
            <div className={styles.form}>
              <div className={styles.typeGroup}>
                <button
                    className={`${styles.typeButton} ${expense.type === 'Income' ? styles.active : ''}`}
                    onClick={() => handleTypeChange('Income')}
                  >
                    Income
                  </button>

                  <button
                    className={`${styles.typeButton} ${expense.type === 'Expense' ? styles.active : ''}`}
                    onClick={() => handleTypeChange('Expense')}
                  >
                    Expense
                  </button>
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="amount" className={styles.label}>
                  Amount
                </label>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  placeholder="100"
                  value={expense.amount}
                  onChange={handleChange}
                  required
                  className={styles.input}
                  step="0.01"
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="description" className={styles.label}>
                  Description
                </label>
                <input
                  type="text"
                  id="description"
                  name="description"
                  placeholder="Grocery shopping"
                  value={expense.description}
                  onChange={handleChange}
                  className={styles.input}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="date" className={styles.label}>
                  Date
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  placeholder=""
                  value={expense.date}
                  onChange={handleChange}
                  required
                  className={styles.input}
                  max={getTodayDate()}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="payment_method" className={styles.label}>
                  Payment Method
                </label>
                <select
                  id="payment_method"
                  name="payment_method"
                  value={expense.payment_method}
                  onChange={handleChange}
                  required
                  className={styles.select}
                >
                  <option value="">Select payment method</option>
                  {[
                    "cash",
                    "credit_card",
                    "debit_card",
                    "bank_transfer",
                    "mobile_money",
                    "crypto",
                    "other",
                  ].map((method) => (
                    <option key={method} value={method}>
                      {method.replace("_", " ").charAt(0).toUpperCase() +
                        method.replace("_", " ").slice(1)}
                    </option>
                  ))}
                </select>
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="category_id" className={styles.label}>
                  Category
                </label>
                {/* <select
                  id="category_id"
                  name="category_id"
                  value={expense.category_id}
                  onChange={handleChange}
                  required
                  className={styles.select}
                >
                  <option value="">Select category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select> */}
                <select
                  id="category_id"
                  name="category_id"
                  value={expense.category_id}
                  onChange={handleChange}
                  required
                  className={styles.select}
                >
                  <option value="">Select category</option>
                  <option value="1">Food &amp; Dining</option>
                  <option value="2">Transportation</option>
                  <option value="3">Housing</option>
                  <option value="4">Utilities</option>
                  <option value="5">Healthcare</option>
                  <option value="6">Entertainment</option>
                  <option value="7">Shopping</option>
                  <option value="8">Personal</option>
                  <option value="9">Education</option>
                  <option value="10">Travel</option>
                </select>
              </div>

              <div className={styles.formGroup}>
                <label className={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    id="is_recurring"
                    name="is_recurring"
                    checked={expense.is_recurring}
                    onChange={handleChange}
                    className={styles.checkbox}
                  />
                  Is this a recurring expense?
                </label>
              </div>

              {expense.is_recurring && (
                <div className={styles.formGroup}>
                  <label
                    htmlFor="recurrence_frequency"
                    className={styles.label}
                  >
                    Recurrence Frequency
                  </label>
                  <select
                    id="recurrence_frequency"
                    name="recurrence_frequency"
                    value={expense.recurrence_frequency}
                    onChange={handleChange}
                    className={styles.select}
                  >
                    <option value="">Select frequency</option>
                    {["daily", "weekly", "monthly", "yearly"].map(
                      (frequency) => (
                        <option key={frequency} value={frequency}>
                          {frequency.charAt(0).toUpperCase() +
                            frequency.slice(1)}
                        </option>
                      )
                    )}
                  </select>
                </div>
              )}

              <div className={styles.formGroup}>
                <label htmlFor="comments" className={styles.label}>
                  Additional Notes
                </label>
                <input
                  type="text"
                  id="comments"
                  name="comments"
                  value={expense.comments}
                  onChange={handleChange}
                  className={styles.input}
                  placeholder="Additional / useful information"
                />
              </div>

              <button
                onClick={handleSubmit}
                className={styles.submitButton}
                disabled={isLoading}
              >
                {isLoading ? `Adding ${expense.type}...` : `Add ${expense.type}`}
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    </>
  );
};

export default ExpenseSidebar;
