import React from "react";
import styles from "../styles/LoadingAnimation.module.scss";

const LoadingDots = () => (
  <div className={styles.loadingAnimation}>
    <div className={styles.dot}></div>
    <div className={styles.dot}></div>
    <div className={styles.dot}></div>
  </div>
);

const LoadingSpinner = () => (
  <svg
    className={styles.loadingSpinner}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className={styles.spinnerBg}
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className={styles.spinnerPath}
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

const PulseAnimation = () => (
  <div className={styles.pulseAnimation}>
    <div className={styles.pulse}></div>
    <div className={styles.pulseCore}></div>
  </div>
);
const LoadingAnimation = ({ isLoading, loadingType }) => {
  return (
    <>
      {isLoading ? (
        <div className={styles.container}>
          {loadingType === "dots" ? (
            <LoadingDots />
          ) : loadingType === "spinner" ? (
            <LoadingSpinner />
          ) : loadingType === "pulse" ? (
            <PulseAnimation />
          ) : (
            <LoadingDots />
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default LoadingAnimation;
