import React, { useState, useEffect } from 'react';

import styles from '../styles/ExpenseOptionsPanel.module.scss'; 

import { useSelector } from "react-redux";

const categories = [
"All",
"Food & Dining",
"Transportation",
"Housing",
"Utilities",
"Healthcare",
"Entertainment",
"Shopping",
"Personal",
"Education",
"Travel"]

const categoryMap = {
"All":0,
"Food & Dining":1,
"Transportation":2,
"Housing":3,
"Utilities":4,
"Healthcare":5,
"Entertainment":6,
"Shopping":7,
"Personal":8,
"Education":9,
"Travel":10}

// 1: "🍽️", // Food & Dining
// 2: "🚗", // Transportation
// 3: "🏠", // Housing
// 4: "⚡", // Utilities
// 5: "🏥", // Healthcare
// 6: "🎮", // Entertainment
// 7: "🛍️", // Shopping
// 8: "👤", // Personal
// 9: "📚", // Education
// 10: "✈️" // Travel

const timeframes = [
  { label: 'Custom', days: 0 },
  { label: '1 Week', days: 7 },
  { label: '1 Month', days: 30 },
  { label: '3 Months', days: 90 },
  { label: '6 Months', days: 180 },
  { label: '1 Year', days: 365 }
];

const CollapsibleSection = ({ title, children, isOpen, onToggle }) => (
  <div className={styles.CollapsibleSection} >
    <button
      onClick={onToggle}
    >
      <span style={{ fontWeight: '600' }}>{title}</span>
      <span>{isOpen ? '▲' : '▼'}</span>
    </button>
    {isOpen && (
      <div>
        {children}
      </div>
    )}
  </div>
);

const CategorySelector = ({ selectedCategories, onCategoryToggle, isDarkMode }) => (
  <div className={styles.CategorySelector}>
    {categories.map(category => (
      <button
        key={category}
        style={{
          backgroundColor: selectedCategories.includes(categoryMap[category]) ? '#3b82f6' : 'transparent',
          color: selectedCategories.includes(categoryMap[category]) ? isDarkMode?'black' : 'white': isDarkMode?'white' : 'black',
        }}
        onClick={() => onCategoryToggle(categoryMap[category])}
      >
        {category}
      </button>
    ))}
  </div>
);

const DateRangeSelector = ({ selectedTimeframe, startDate, endDate, onTimeframeChange, onStartDateChange, onEndDateChange }) => (
  <div className={styles.DateRangeSelector}>
    <select
      value={selectedTimeframe}
      onChange={(e) => onTimeframeChange(e.target.value)}
    >
      {timeframes.map(({ label }) => (
        <option key={label} value={label}>{label}</option>
      ))}
    </select>
    <div className={styles.dateRange}>
      <input
        type="date"
        value={startDate}
        onChange={(e) => onStartDateChange(e.target.value)}
      />
      <span>to</span>
      <input
        type="date"
        value={endDate}
        onChange={(e) => onEndDateChange(e.target.value)}
      />
    </div>
  </div>
);

const ToggleExpenseType = ({ onToggle, isDarkMode,setTransactionType,transactionType }) => {

  const handleToggle = (val) => {
    setTransactionType(val);
  };

  return (
    <div className={`${styles.toggleGroup} ${isDarkMode ? styles.darkMode : ''}`} role="group">
      <button
        type="button"
        className={`${styles.button} ${
          transactionType=="All" ? styles.incomeActive : styles.inactive
        }`}
        onClick={()=>handleToggle("All")}
      >
        All
      </button>
      <button
        type="button"
        className={`${styles.button} ${
          transactionType=="Income" ? styles.incomeActive : styles.inactive
        }`}
        onClick={()=>handleToggle("Income")}
      >
        Income
      </button>
      <button
        type="button"
        className={`${styles.button} ${
          transactionType=="Expense" ? styles.expensesActive : styles.inactive
        }`}
        onClick={()=>handleToggle("Expense")}
      >
        Expenses
      </button>
    </div>
  );
};

const ExpenseOptionsPanel = ({ selectedCategories, setSelectedCategories, dateRange, setDateRange, selectedTimeframe, setSelectedTimeframe,setTransactionType, transactionType }) => {
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);

  const isDarkMode = useSelector(state => state.theme.darkMode);

  const handleCategoryToggle = (category) => {
    if (category === 0) {
      setSelectedCategories([0]);
    } else {
      setSelectedCategories(prev => {
        const newSelection = prev.filter(c => c !== 0);
        if (newSelection.includes(category)) {
          newSelection.splice(newSelection.indexOf(category), 1);
        } else {
          newSelection.push(category);
        }
        return newSelection.length ? newSelection : [0];
      });
    }
  };

  const handleTimeframeChange = (value) => {
    setSelectedTimeframe(value);
    const timeframe = timeframes.find(t => t.label === value);
    if (timeframe && timeframe.days > 0) {
      const newStartDate = new Date(Date.now() - timeframe.days * 24 * 60 * 60 * 1000);
      const newEndDate = new Date();
      // setStartDate(newStartDate.toISOString().split('T')[0]);
      // setEndDate(newEndDate.toISOString().split('T')[0]);
      setDateRange({startDate:newStartDate.toISOString().split('T')[0],endDate:newEndDate.toISOString().split('T')[0]});
    }
  };

  const handleStartDateChange = (value) => {
    setDateRange(state=>({...state,startDate:value}));
    setSelectedTimeframe('Custom');
  };
  
  const handleEndDateChange = (value) => {
    setDateRange(state=>({...state,endDate:value}));
    setSelectedTimeframe('Custom');
  };

  useEffect(() => {
    if (selectedTimeframe !== 'Custom') {
      const timeframe = timeframes.find(t => t.label === selectedTimeframe);
      if (timeframe) {
        const newStartDate = new Date(Date.now() - timeframe.days * 24 * 60 * 60 * 1000);
        const newEndDate = new Date();
        setDateRange({startDate:newStartDate.toISOString().split('T')[0],endDate:newEndDate.toISOString().split('T')[0]});
      }
    }
  }, [selectedTimeframe]);

  return (
    <>
      <ToggleExpenseType setTransactionType={setTransactionType} transactionType={transactionType} isDarkMode={isDarkMode}/>
      <section className={`${styles.ExpenseOptionsPanel} ${isDarkMode ? styles.darkMode : ''}`}>
        <CollapsibleSection
          title={`Categories (${selectedCategories.length === 1 && selectedCategories[0] === 0 ? 'All' : selectedCategories.length})`}
          isOpen={isCategoryOpen}
          onToggle={() => setIsCategoryOpen(!isCategoryOpen)}
        >
          <CategorySelector 
            selectedCategories={selectedCategories} 
            onCategoryToggle={handleCategoryToggle} 
            isDarkMode={isDarkMode}
          />
        </CollapsibleSection>

        <CollapsibleSection
          title={`Date Range (${selectedTimeframe})`}
          isOpen={isDateRangeOpen}
          onToggle={() => setIsDateRangeOpen(!isDateRangeOpen)}
        >
          <DateRangeSelector 
            selectedTimeframe={selectedTimeframe}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            onTimeframeChange={handleTimeframeChange}
            onStartDateChange={handleStartDateChange}
            onEndDateChange={handleEndDateChange}
          />
        </CollapsibleSection>
      </section>
      </>
  );
};

export default ExpenseOptionsPanel;