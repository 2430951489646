import React, { useState, useEffect } from "react";
import {
  ArrowLeft,
  Bell,
  Search,
  TrendingUp,
  DollarSign,
  CreditCard,
  Settings,
  User,
  Trash,
  Trash2,
  LogOut,
  Sun,
  Moon,
  Download,
} from "lucide-react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styles from "../styles/Dashboard.module.scss";
import Sidebar from "../components/ExpenseSidebar";
import Header from "../components/Header";
import ExpenseOptionsPanel from "../components/ExpenseOptionsPanel";

import { useDispatch, useSelector } from "react-redux";
import { toggleDarkMode } from '../redux/theme/ThemeSlice';
import { fetchExpenses, addNewExpense, syncExpenses, setOnlineStatus } from '../redux/expense/ExpenseSlice';


import PaginationControls from '../components/PaginationControls';
import ExpenseChart from "../components/ExpenseChart";
import Background from "../components/Background";

const Dashboard = () => {

  const dispatch = useDispatch();
  const expensesState = useSelector(state => state.expense);
  const { items: expenses = [], isOnline = navigator.onLine, status = 'idle' } = expensesState || {};

  const [statistics, setStatistics] = useState();
  const [filtered,setFiltered] = useState();
  const [transactions, setTransactions] = useState();
  const [isOpen, setIsOpen] = useState(false);
  
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });

  const [transactionType, setTransactionType] = useState('All');

  const [selectedCategories, setSelectedCategories] = useState([0]);
  
  const [selectedTimeframe, setSelectedTimeframe] = useState('1 Month');

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  // Reset to first page when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [dateRange, setSelectedCategories]); 
  
  // Filter expenses based on date range and category
  const filteredExpenses = expenses.filter(expense => {
    const expenseDate = new Date(expense.date);
    const start = dateRange.startDate ? new Date(dateRange.startDate) : null;
    const end = dateRange.endDate ? new Date(dateRange.endDate) : null;
    const dateInRange = (!start || expenseDate >= start) && 
                        (!end || expenseDate <= end);
    const categoryMatch = selectedCategories.includes(0) || 
                          selectedCategories.includes(expense.category);
    if(transactionType === 'All') return dateInRange && categoryMatch; // If All is selected, ignore transactionType
    
    const transactionTypeMatch = expense.type === transactionType;
    return dateInRange && categoryMatch && transactionTypeMatch;
  });
  
    // Pagination calculations
    const totalItems = filteredExpenses.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
    const currentExpenses = filteredExpenses.slice(startIndex, endIndex);
  
    // Pagination controls
    const goToPage = (page) => {
      setCurrentPage(Math.min(Math.max(1, page), totalPages));
    };
  
    const calculateTotals = () => {
      const totals = {
        overall: 0,
        byCategory: {}
      };
  
      filteredExpenses.forEach(expense => {
        const amount = parseFloat(expense.amount);
        totals.overall += amount;
        
        if (!totals.byCategory[expense.category]) {
          totals.byCategory[expense.category] = 0;
        }
        totals.byCategory[expense.category] += amount;
      });
  
      return totals;
    };
  
  const totals = calculateTotals();

  const isDarkMode = useSelector((state) => state.theme.darkMode);

  const categoryIcons = {
    1: "🍽️", // Food & Dining
    2: "🚗", // Transportation
    3: "🏠", // Housing
    4: "⚡", // Utilities
    5: "🏥", // Healthcare
    6: "🎮", // Entertainment
    7: "🛍️", // Shopping
    8: "👤", // Personal
    9: "📚", // Education
    10: "✈️" // Travel
  };
  
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setStatistics({
      total: 20854.98,
      data: [
        { month: "Jan", value: 15000 },
        { month: "Feb", value: 15000 },
        { month: "March", value: 15000 },
        { month: "Apr", value: 15000 },
        { month: "May", value: 18000 },
        { month: "Jun", value: 17000 },
        { month: "Jul", value: 19000 },
        { month: "Aug", value: 20854.98 },
        { month: "Sep", value: 22000 },
        { month: "Oct", value: 22000 },
        { month: "Nov", value: 22000 },
        { month: "Dec", value: 22000 },
      ],
    });
  }, []);

  // Fetch expenses when the component is mounted
  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchExpenses());
    }
  }, [dispatch, status]);

  // Format expenses for the chart
  const chartExpenses = filteredExpenses.map(expense => ({
    date: expense.date,
    amount: parseFloat(expense.amount),
    category: expense.category
  }));

  return (
    <main
      className={`${styles.appContainer} ${isOpen ? styles.open : styles.closed}`}
    >
      <Sidebar toggleSidebar={toggleSidebar} isOpen={isOpen} />
      <Header/>
      <div className={`${styles.dashboard} ${isDarkMode ? styles.darkMode : ''}`}>
        {statistics && filteredExpenses ? (
          <main className={styles.main}>
            <ExpenseOptionsPanel 
              transactionType={transactionType}
              setTransactionType={setTransactionType}
              selectedCategories={selectedCategories} 
              setSelectedCategories={setSelectedCategories} 
              dateRange={dateRange}
              setDateRange={setDateRange}
              selectedTimeframe = {selectedTimeframe}
              setSelectedTimeframe = {setSelectedTimeframe}
            />
            <section className={`${styles.statsSection} ${isDarkMode ? styles.darkMode : ''}`}>
              <div className={styles.statsHeader}>
                <h2>Statistics</h2>
                <Search className={styles.icon} />
              </div>
              <h2 className={styles.totalAmount}>
                {/* $
                {totals.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} */}
                $ {totals.overall.toFixed(2)}
              </h2>
              <p className={styles.subtitle}>Total Balance</p>

              <ExpenseChart  
                expenses={chartExpenses}
                customStartDate = {dateRange.startDate}
                customEndDate = {dateRange.endDate}
                selectedTimeframe = {selectedTimeframe}/>
            </section>

            <section className={`${styles.quickActions} ${isDarkMode ? styles.darkMode : ''}`}>
              <button className={styles.actionButton}>
                <TrendingUp size={20} /> Invest
              </button>
              <button className={styles.actionButton}>
                <DollarSign size={20} /> Transfer
              </button>
              <button className={styles.actionButton}>
                <CreditCard size={20} /> Cards
              </button>
              <button className={styles.actionButton}>
                <Download size={20} /> Export
              </button>
            </section>

            <section className={`${styles.historySection} ${isDarkMode ? styles.darkMode : ''}`}>
              <div className={styles.historyHeader}>
                <h2>Transaction History</h2>
                <Search className={styles.icon} />
              </div>
              
              <ul className={styles.transactionList}>
                
                {filteredExpenses.length === 0 ? (
                    <p className="text-gray-500">No {transactionType} found for the selected criteria.</p>
                  ) : (
                    <>
                      <ul className="space-y-2">
                        {currentExpenses.map((transaction) => (
                          <li key={transaction.id} className={styles.transactionItem}>
                          <div className={styles.transactionIcon}>
                            {categoryIcons[transaction.category_id]}
                          </div>
                          <div className={styles.transactionInfo}>
                            <p className={styles.transactionName}>
                              {transaction.description}
                            </p>
                            <p className={styles.transactionName}>
                              {transaction.payment_method}
                            </p>
                            <p className={styles.transactionDate}>
                              {transaction.date}
                            </p>
                          </div>
                          <p className={`${styles.transactionAmount} ${transaction.type==="Income" ? styles.income:""} ${transaction.type==="Expense" ? styles.expense:""}`}>
                            ${Math.abs(transaction.amount).toFixed(2)}
                          </p>
                        </li>
                        ))}
                      </ul>
                    </>
                  )}
              </ul>
              
              <PaginationControls 
                transactionType={transactionType}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                totalItems={totalItems}
                goToPage={goToPage}
                startIndex={startIndex}
                endIndex={endIndex}
                totalPages={totalPages}/>
            </section>
          </main>
        ) : (
          "Loading..."
        )}
      </div>
    </main>
  );
};

export default Dashboard;
