import React from 'react';
import { useSpring, animated, to } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import styles from '../styles/CardIllustration.module.scss';

// Import all background images
import bg1 from '../assets/backgrounds/bg-1.png';
import bg2 from '../assets/backgrounds/bg-2.png';
import bg3 from '../assets/backgrounds/bg-3.png';
import bg4 from '../assets/backgrounds/bg-4.png';
import bg5 from '../assets/backgrounds/bg-5.png';

const backgroundImages = [bg1, bg2, bg3, bg4, bg5];

const CardIllustration = ({ 
  currentSlide, 
  totalSlides, 
  prevSlide, 
  nextSlide, 
  isDarkMode 
}) => {
  // Card rotation animation
  const cardAnimation = useSpring({
    rotate: isDarkMode ? 5 : 0,
    config: { tension: 300, friction: 10 },
  });

  // Swipe animation
  const [{ x }, api] = useSpring(() => ({
    x: 0,
    config: { tension: 300, friction: 26 },
  }));

  const bind = useDrag(({ down, movement: [mx], active, cancel }) => {
    // Define minimum distance for swipe
    const SWIPE_THRESHOLD = 50;
    
    if (active && down) {
      // During drag
      api.start({ 
        x: mx,
        immediate: true
      });

      // Check if we've crossed the threshold
      if (Math.abs(mx) > SWIPE_THRESHOLD) {
        if (mx > 0 && currentSlide > 0) {
          prevSlide();
          cancel(); // Cancel current gesture
        } else if (mx < 0 && currentSlide < totalSlides - 1) {
          nextSlide();
          cancel(); // Cancel current gesture
        }
      }
    } else {
      // On release, reset position
      api.start({ 
        x: 0,
        immediate: false,
        config: { tension: 300, friction: 26 }
      });
    }
  }, {
    bounds: { left: -100, right: 100 },
    rubberband: true,
    filterTaps: true,
    axis: 'x',
    pointer: { touch: true },
    from: () => [x.get(), 0] // Start from current x position
  });

  return (
    <div className={styles.cardContainer}>
      <animated.div
        {...bind()}
        style={{
          transform: to(
            [x, cardAnimation.rotate],
            (x, rotate) => `translate3d(${x}px, 0, 0) rotate(${rotate}deg)`
          ),
          touchAction: 'pan-y',
        }}
        className={styles.card}
      >
        <div className={styles.cardContent}>
          <img 
            src={backgroundImages[currentSlide]}
            alt={`Card background ${currentSlide + 1}`}
            className={styles.cardBackground}
            draggable="false"
          />
        </div>
      </animated.div>
    </div>
  );
};

export default CardIllustration;