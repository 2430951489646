// App.js
import React, { useState, useEffect } from 'react';
import Dashboard from './pages/Dashboard';
import LandingPage from './pages/LandingPage';

import Terms from './pages/Terms'
import Policy from './pages/Policy'

import { setOnlineStatus, syncExpenses } from './redux/expense/ExpenseSlice';
import { useDispatch, useSelector } from 'react-redux';

import { verifyToken, logout } from './redux/user/UserSlice';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  // Import Router and Routes from react-router-dom

const App = () => {
  
  const { isAuthenticated } = useSelector((state) => state.user);


  const dispatch = useDispatch();

  const { isOnline = navigator.onLine } = useSelector((state) => state.expense || {});

  useEffect(() => {
    // dispatch(fetchExpenses());

    const handleOnline = () => dispatch(setOnlineStatus(true));
    const handleOffline = () => dispatch(setOnlineStatus(false));

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [dispatch]);

  useEffect(() => {
    if (isOnline&&isAuthenticated) {
      dispatch(syncExpenses());
    }
  }, [isOnline, dispatch]);

  useEffect(() => {
    dispatch(verifyToken()).unwrap().catch(() => {
      dispatch(logout()); // Logout if token verification fails
    });
    // Set up a timer to verify token every 5 minutes
    const interval = setInterval(() => {
      dispatch(verifyToken()).unwrap().catch(() => {
        dispatch(logout()); // Logout if token verification fails
      });
    }, 5 * 60 * 1000); // 5 minutes

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        {/* Define routes for authenticated and non-authenticated users */}
        <Route path="/" element={isAuthenticated ? <Dashboard /> : <LandingPage />} />
        <Route path="/terms" element={<Terms />} />  {/* Route for the policy page */}
        <Route path="/policy" element={<Policy />} />  {/* Route for the policy page */}
      </Routes>
    </Router>
  );
};

export default App;