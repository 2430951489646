import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  initDB,
  addExpense,
  getExpenses,
  addToSyncQueue,
  getSyncQueue,
  clearSyncQueue,
  markExpenseAsSynced,
  updateExpense,
} from "../../services/database";

import { useSelector } from 'react-redux';
import { useAuth } from "../../utils/AuthContext";

// API service
const API_BASE_URL = 'https://api.smart-spending.com';

const api = {
  async createExpense(expense) {
    const item = expense;
    delete item.syncStatus;
    delete item.timestamp;
    const response = await fetch(`${API_BASE_URL}/expense`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("smart_user")}`,
      },
      body: JSON.stringify(item),
    });
    if (!response.ok) throw new Error('Failed to create expense');
    return response.json();
  },

  async updateExpense(expense) {
    const response = await fetch(`${API_BASE_URL}/${expense.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("smart_user")}`,
      },
      body: JSON.stringify(expense),
    });
    if (!response.ok) throw new Error('Failed to update expense');
    return response.json();
  },

  async fetchExpenses() {
    const response = await fetch(API_BASE_URL);
    if (!response.ok) throw new Error('Failed to fetch expenses');
    return response.json();
  }
};

export const fetchExpenses = createAsyncThunk(
  "expenses/fetchExpenses",
  async (_, { getState }) => {
    await initDB();
    
    // If online, fetch from API and update IndexedDB
    if (getState().expense.isOnline) {
      try {
        const apiExpenses = await api.fetchExpenses();
        // Update local database with API data
        for (const expense of apiExpenses) {
          await updateExpense(expense);
        }
        return apiExpenses;
      } catch (error) {
        console.error('Failed to fetch from API, falling back to IndexedDB:', error);
      }
    }
    
    // Fallback to IndexedDB
    return await getExpenses();
  }
);

export const addNewExpense = createAsyncThunk(
  "expenses/addNewExpense",
  async (expense, { getState, rejectWithValue }) => {
    try {
      const newExpense = {
        ...expense,
        created_at: new Date().toISOString(),
        syncStatus: 'pending'
      };

      // Always save to IndexedDB first
      const localId = await addExpense(newExpense);
      const expenseWithId = { ...newExpense, id: localId };

      // If online, try to sync immediately
      if (getState().expense.isOnline) {
        try {
          const syncedExpense = await api.createExpense(expenseWithId);
          await markExpenseAsSynced(localId, syncedExpense.id);
          return syncedExpense;
        } catch (error) {
          // If API sync fails, add to sync queue
          await addToSyncQueue(expenseWithId);
          return expenseWithId;
        }
      } else {
        // If offline, add to sync queue
        await addToSyncQueue(expenseWithId);
        return expenseWithId;
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const syncExpenses = createAsyncThunk(
  "expenses/syncExpenses",
  async (_, { dispatch, getState }) => {
    if (!getState().expense.isOnline) {
      throw new Error('Cannot sync while offline');
    }

    const syncQueue = await getSyncQueue();
    const results = {
      success: [],
      failed: []
    };

    for (const item of syncQueue) {
      try {
        const syncedExpense = await api.createExpense(item);
        await markExpenseAsSynced(item.id, syncedExpense.id);
        results.success.push(syncedExpense);
        dispatch(updateSyncedExpense(syncedExpense));
      } catch (error) {
        results.failed.push({ item, error: error.message });
      }
    }

    // Only clear successfully synced items
    await Promise.all(
      results.success.map(expense => 
        clearSyncQueue(expense.id)
      )
    );

    return results;
  }
);

const expenseSlice = createSlice({
  name: "expenses",
  initialState: {
    items: [],
    isOnline: navigator.onLine,
    status: "idle",
    syncStatus: {
      lastSync: null,
      pendingSync: 0,
      error: null
    },
    error: null,
  },
  reducers: {
    setOnlineStatus: (state, action) => {
      state.isOnline = action.payload;
      if (action.payload && state.syncStatus.pendingSync > 0) {
        state.status = 'needsSync';
      }
    },
    updateSyncedExpense: (state, action) => {
      const index = state.items.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.items[index] = { ...action.payload, syncStatus: 'synced' };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpenses.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchExpenses.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
      })
      .addCase(fetchExpenses.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewExpense.fulfilled, (state, action) => {
        state.items.push(action.payload);
        state.syncStatus.pendingSync += 1;
      })
      .addCase(syncExpenses.pending, (state) => {
        state.syncStatus.error = null;
      })
      .addCase(syncExpenses.fulfilled, (state, action) => {
        state.syncStatus.lastSync = new Date().toISOString();
        state.syncStatus.pendingSync -= action.payload.success.length;
        if (action.payload.failed.length > 0) {
          state.syncStatus.error = `Failed to sync ${action.payload.failed.length} items`;
        }
      })
      .addCase(syncExpenses.rejected, (state, action) => {
        state.syncStatus.error = action.error.message;
      });
  },
});

export const { setOnlineStatus, updateSyncedExpense } = expenseSlice.actions;

export default expenseSlice.reducer;