// MoneyManagementIntro.js
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

import CardIllustration from "../components/CardIllustration.jsx";
import SlideContent from "../components/SlideContent.jsx";
import Navigation from "../components/Navigation.jsx";
import ActionButtons from "../components/ActionButtons.jsx";
import LoginModal from "../components/LoginModal.jsx";

import { useDispatch, useSelector } from "react-redux";
import ThemeToggle from "../components/ThemeToggle.jsx";

import styles from "../styles/LandingPage.module.scss";
import Background from "../components/Background.jsx";

const slides = [
  {
    title: "Save with Confidence",
    description:
      "Take Control - Gain confidence in your finances with clear, simple tools",
  },
  {
    title: "Track your expenses",
    description: "Smart Budgeting - Easily track your spending and savings with intuitive tools",
  },
  {
    title: "Financial Freedom",
    description: "Achieve Financial Freedom - Start saving today for the life you want tomorrow",
  },
  {
    title: "Emergency Fund Security",
    description: "Prepare for the Unexpected - Build a safety net with our guided savings plans",
  },
  {
    title: "Grow Your Savings ",
    description: "Watch Your Savings Grow - Make every dollar count and build wealth over time",
  },
];

const LandingPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const nextSlide = () => setCurrentSlide((prev) => (prev + 1) % slides.length);
  const prevSlide = () =>
  setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const dispatch = useDispatch();

  const isDarkMode = useSelector((state) => state.theme.darkMode);

  const slideAnimation = useSpring({
    opacity: 1,
    transform: "translateX(0%)",
    from: { opacity: 0, transform: "translateX(100%)" },
    reset: true,
    config: { tension: 280, friction: 60 },
  });

  // const toggleDarkMode = () => {
  //   dispatch(toggleDarkMode());
  // }

  return (
    <div className={`${styles.container} ${isDarkMode ? styles.darkMode : ""}`}>

      <Background/>
      <LoginModal isOpen={isModalOpen} onClose={closeModal} />

      <div className={styles.themeToggleDiv}>
        <ThemeToggle />
      </div>

      <CardIllustration
        currentSlide={currentSlide}
        totalSlides={slides.length}
        prevSlide={prevSlide}
        nextSlide={nextSlide}
        isDarkMode={isDarkMode}
      />

      <animated.div style={slideAnimation}>
        <SlideContent slide={slides[currentSlide]} />
      </animated.div>

      <Navigation
        currentSlide={currentSlide}
        totalSlides={slides.length}
        prevSlide={prevSlide}
        nextSlide={nextSlide}
        isDarkMode={isDarkMode}
      />

      <ActionButtons
        nextSlide={nextSlide}
        openModal={openModal}
        isDarkMode={isDarkMode}
      />

      <button className={styles.skipButton}>Smart Spending</button>
    </div>
  );
};

export default LandingPage;
