import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('smart_user'));

  useEffect(() => {
    if (token) {
      localStorage.setItem('smart_user', token);
    } else {
      localStorage.removeItem('smart_user');
    }
  }, [token]);

  const endpoint = 'https://api.smart-spending.com/login' 
  const authLogin = async (email, password) => {
    try {

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({'email':email, 'password':password }),
      });
      
      if (response.ok) {
        const data = await response.json();
    
        // Assuming the JWT is included in the response data, e.g., data.token
        const token = data.access_token;
    
        if (token) {
          localStorage.setItem('smart_user', token);
          return({ type: 'success', text: 'Login successful!' });
        } else {
          console.error('JWT token not found in response.');
          return({ type: 'error', text: 'Invalid email or password.' });
        }
      } else {
        console.error('Error:', await response.text());
        
        return({ type: 'error', text: 'Invalid email or password.' });
      }
    } catch (error) {
      console.error('Network error:', error);
      return({ type: 'error', text: 'Server Error: Kindly try again later' });
    }

  };

  const authRegistration = async(username, email, password) => {
    const endpoint = 'https://api.smart-spending.com/register';

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'username' : username, 'email' : email , 'password' : password }),
      });
      
      if (response.ok) {
          return({ type: 'success', text: 'Registered successful!' });
      } else {
        const data = await response.json();
        return({ type: 'error', text: data.message });
      }
    } catch (error) {
      return({ type: 'error', text: 'Server Error: Kindly try again later' });
    }
  }

  const verifyToken = async (token) => {
    try {
      const response = await fetch('https://api.test.com/verify-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Token is invalid or expired');
      }
  
      const data = await response.json();
      return data.valid; // Assuming your API returns { valid: true/false }
    } catch (error) {
      console.error('Token verification failed:', error);
      return false;
    }
  };
  

  const logout = () => {
    setToken(null);
    localStorage.removeItem('smart_user');
  };

  const getToken = () => token;

  return (
    <AuthContext.Provider value={{ token, authLogin, authRegistration,  logout, getToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};


