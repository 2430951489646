import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import styles from '../styles/Navigation.module.scss';

const Navigation = ({ currentSlide, totalSlides, prevSlide, nextSlide, isDarkMode }) => (
  <div className={styles.navigation}>
    <button onClick={prevSlide} className={`${styles.navButton} ${isDarkMode ? styles.darkMode : ''}`}>
      <ChevronLeft size={24} color={isDarkMode?"white":"black"}/>
    </button>
    <div className={styles.indicators}>
      {[...Array(totalSlides)].map((_, index) => (
        <div
          key={index}
          className={`${styles.indicator} ${index === currentSlide ? styles.active : ''} ${isDarkMode ? styles.darkMode : ''}`}
        ></div>
      ))}
    </div>
    <button onClick={nextSlide} className={`${styles.navButton} ${isDarkMode ? styles.darkMode : ''}`}>
      <ChevronRight size={24} color={isDarkMode?"white":"black"}/>
    </button>
  </div>
);

export default Navigation;