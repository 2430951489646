import React from 'react';
import styles from "../styles/Background.module.scss";

import background from '../assets/background.png';

const Background = () => {
    return (
        <>
            <img src={background} className={styles.background}/>
        </>
    );
}

export default Background;
