import React from 'react';
import styles from '../styles/ActionButtons.module.scss';

const ActionButtons = ({ nextSlide, openModal, isDarkMode }) => (
  <div className={styles.actionButtons}>
    <button onClick={openModal} className={`${styles.loginButton} ${isDarkMode ? styles.darkMode : ''}`}>
      Get Started
    </button>
    <button onClick={nextSlide} className={`${styles.nextButton} ${isDarkMode ? styles.darkMode : ''}`}>
      Next →
    </button>
  </div>
);

export default ActionButtons;