import React, { useState, useEffect, useRef } from "react";
import {
  ArrowLeft,
  Bell,
  ChevronLeft,
  Search,
  TrendingUp,
  DollarSign,
  CreditCard,
  Settings,
  User,
  Trash,
  LogOut,
  Sun,
  Moon,
  Download,
  Menu,
  FolderSync,
  WarehouseIcon,
  WifiOff,
  Wifi
} from "lucide-react";

import styles from "../styles/Header.module.scss";

import { useDispatch, useSelector } from "react-redux";

import { toggleDarkMode } from '../redux/theme/ThemeSlice';

import {logout} from "../redux/user/UserSlice";
import ThemeToggle from "./ThemeToggle";

const Header = () => {

    const [UserOptionisOpen,setUserOptionisOpen] = useState(false);

    const dispatch = useDispatch();
    const isDarkMode = useSelector((state) => state.theme.darkMode);

    const userOptionsRef = useRef(null);

    const toggleUserOptions = () => {
        setUserOptionisOpen((curr) => !curr);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the click is outside the userOptions div
            if (userOptionsRef.current && !userOptionsRef.current.contains(event.target)) {
                setUserOptionisOpen(false); // Close the options
            }
        };

        // Add event listener when the component is mounted
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener when the component is unmounted
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [userOptionsRef]);
    
    const { isOnline = navigator.onLine } = useSelector((state) => state.expense || {});

    const handleLogout = () => {
        dispatch(logout());
      };
    
    return (
        <header className={`${styles.header} ${isDarkMode? styles.darkMode : ''}`}>
            <div className={styles.userOpts}>
            <button className={styles.mainHeaderBtn} onClick={toggleUserOptions}>
                <Menu className={styles.icon} />
            </button>
            {UserOptionisOpen?
            <div ref={userOptionsRef} className={styles.opts}>
                <div className={styles.rowTop}>
                    <ChevronLeft onClick={toggleUserOptions} className={styles.icon} />
                    <div className={styles.userDetails}>
                        <span>Welcome Back</span>
                        <span>username@email.com</span>
                    </div>
                    <div className={styles.themeToggle}>
                        <ThemeToggle/>
                    </div>
                </div>

                <span className={styles.heading}>Settings</span>
                <button className={styles.border_top}>
                    <FolderSync className={styles.icon} /> Force Sync
                </button>
                <button>
                    <Trash className={styles.icon} /> Delete Local
                </button>
                <button className={styles.border_bottom}>
                    <WarehouseIcon className={styles.icon} /> Delete Account
                </button>
                <button onClick={handleLogout}>
                    <LogOut className={styles.icon} /> Log Out
                </button>
            </div>
            :""}
            </div>
            {/* <h1>Statistics</h1> */}
            <div className={styles.status}>
                <span>{isOnline ? 'You are Online' : 'You are Offline'}</span>
                {isOnline ? (
                <Wifi/>
                ) : (
                <WifiOff/>
                )}
            </div>
        </header>
    );
}

export default Header;
