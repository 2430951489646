import React from 'react';
// import styles from './LegalPages.module.scss';

const Terms = () => {
  return (
    <div>
      
              <section>
                <h2>1. Terms</h2>
                <p>
                  By accessing and using this application, you accept and agree to be bound by the terms and provisions of this agreement. If you do not agree to abide by these terms, please do not use this application.
                </p>
              </section>

              <section>
                <h2>2. Use License</h2>
                <p>
                  This application is provided free of charge for personal use. You may not:
                </p>
                <ul>
                  <li>Modify or copy the materials</li>
                  <li>Use the materials for any commercial purpose</li>
                  <li>Attempt to reverse engineer any software contained in the application</li>
                  <li>Remove any copyright or other proprietary notations</li>
                  <li>Transfer the materials to another person</li>
                </ul>
              </section>

              <section>
                <h2>3. Disclaimer</h2>
                <p>
                  The materials within this application are provided on an 'as is' basis. The owner makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation:
                </p>
                <ul>
                  <li>Merchantability</li>
                  <li>Fitness for a particular purpose</li>
                  <li>Non-infringement of intellectual property</li>
                  <li>Accuracy, reliability, and availability of the service</li>
                </ul>
              </section>

              <section>
                <h2>4. Limitations</h2>
                <p>
                  In no event shall the owner be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use this application.
                </p>
              </section>

              <section>
                <h2>5. Revisions</h2>
                <p>
                  The owner may revise these terms of service at any time without notice. By using this application, you are agreeing to be bound by the current version of these terms of service.
                </p>
              </section>

              <section>
                <h2>1. Data Collection</h2>
                <p>
                  We collect only the minimum information necessary to provide you with our service:
                </p>
                <ul>
                  <li>Email address (for authentication)</li>
                  <li>Basic profile information (username)</li>
                  <li>Information you choose to provide within the application</li>
                </ul>
              </section>

              <section>
                <h2>2. Use of Data</h2>
                <p>
                  Your data is used exclusively within the application to:
                </p>
                <ul>
                  <li>Provide and maintain the service</li>
                  <li>Authenticate your account</li>
                  <li>Enable features and functionality you request</li>
                </ul>
              </section>

              <section>
                <h2>3. Data Protection</h2>
                <p>
                  We implement industry-standard security measures to protect your data:
                </p>
                <ul>
                  <li>Secure data transmission (HTTPS)</li>
                  <li>Password hashing</li>
                  <li>Database security best practices</li>
                </ul>
              </section>

              <section>
                <h2>4. Third-Party Access</h2>
                <p>
                  We do not share your personal data with any third parties. Your information remains within the application and is used solely for providing the service.
                </p>
              </section>

              <section>
                <h2>5. Your Rights</h2>
                <p>
                  You have the right to:
                </p>
                <ul>
                  <li>Access your personal data</li>
                  <li>Request correction of your data</li>
                  <li>Request deletion of your data</li>
                  <li>Export your data</li>
                </ul>
              </section>

              <section>
                <h2>6. Contact</h2>
                <p>
                  For any privacy-related questions or concerns, please contact us through the application's support channels.
                </p>
              </section>
    </div>
  );
};

export default Terms;