import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.smart-spending.com', // Replace with your API base URL
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const res = await api.post('/refresh-token', { refreshToken });
        if (res.data.token) {
          localStorage.setItem('token', res.data.token);
          api.defaults.headers['Authorization'] = 'Bearer ' + res.data.token;
          return api(originalRequest);
        }
      } catch (refreshError) {
        console.log('Unable to refresh token', refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default api;