import React, { useRef, useState, useEffect } from 'react';

import LoadingAnimation from './LoadingAnimation';

import { useDispatch, useSelector } from 'react-redux';
import { loginUser, registerUser, clearError} from '../redux/user/UserSlice';
import { useAuth } from '../utils/AuthContext';

import styles from '../styles/LoginModal.module.scss';
import Background from './Background';

const Message = ({ type, children }) => (
  <div className={`${styles.message} ${styles[type]}`}>
    {children}
  </div>
);

const LoginModal = ({ isOpen, onClose, setIsLoggedIn }) => {
  
  const { status, error, isAuthenticated } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // const { authLogin, authRegistration } = useAuth();
  const isDarkMode = useSelector(state=>state.theme.darkMode)
  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [ConfirmPassword, setConfirmPassword] = useState('');
  const [ConfirmPasswordErr, setConfirmPasswordErr] = useState('');
  const [username, setUserName] = useState('');

  const toggleMode = () => {
    setIsLogin(!isLogin);
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    return
  }

  const handleLogin= async (e) => {
    e.preventDefault();
    dispatch(loginUser({"email":email, "password":password}));
  };

  const handleRegister = async (e) => {
    setIsLoading(true);
    e.preventDefault();
  
    if (ConfirmPassword === password) {
      setConfirmPasswordErr(false);
      dispatch(
          registerUser({ username, email, password })
        );
    } else {
      setConfirmPasswordErr(true);
    }
  
    setIsLoading(false);
  };
  const modalRef = useRef();
  
  useEffect(() => {
    const handleClickOutside = (event) => {
        // Check if the click is outside the userOptions div
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose() // Close the options
        }
    };

    // Add event listener when the component is mounted
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener when the component is unmounted
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  if (!isOpen) return null;

  
  return (
    <>
    <LoadingAnimation isLoading={status === 'loading'} loadingType="spinner" />
    <div className={styles.modalOverlay}>
      <div ref={modalRef} className={`${styles.modalContainer} ${isDarkMode?styles.darkMode:""}`}>
        <h2>{isLogin ? 'Login' : 'Register'}</h2>

        <button className={styles.googleButton} onClick={()=>(console.log("Login with google"))}>
          Sign in with Google
        </button>
        <CenteredTextWithLines/>
        <form onSubmit={ isLogin ? handleLogin : handleRegister}>

          {status === "succeeded" || status === "failed" && error?.message  && <Message type={status}>{error.message}</Message>}

          {!isLogin && (
            <input
              type="text"
              placeholder="Name"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              required
            />
          )}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => {setEmail(e.target.value);dispatch(clearError());}}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => {setPassword(e.target.value);dispatch(clearError());}}
            required
          />
          {!isLogin && (
            <input
              className={ConfirmPasswordErr?styles.error:""}
              type="password"
              placeholder="Confirm Password"
              value={ConfirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          )}
          <button type="submit">{isLogin ? 'Login' : 'Register'}</button>
        </form>
        <p>
          {isLogin ? "Don't have an account? " : "Already have an account? "}
          <button onClick={toggleMode}>{isLogin ? 'Register' : 'Login'}</button>
        </p>
        <button className={styles.closeButton} onClick={onClose}>
          close
        </button>
        {/* {status === 'loading' ? 'Logging in...' : 'Login'}
        {isAuthenticated?"true":"false"} */}
      </div>
    </div>
    </>
  );
};

export default LoginModal;


function CenteredTextWithLines() {
  return (
    <div className={styles.horizontalLineContainer}>
      <div className={styles.horizontalLine}></div>
      <span className={styles.centeredText}>or</span>
      <div className={styles.horizontalLine}></div>
    </div>
  );
}