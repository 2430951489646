// database.js
const DB_NAME = 'ExpenseTrackerDB';
const DB_VERSION = 1;
const EXPENSE_STORE = 'expenses';
const SYNC_QUEUE_STORE = 'syncQueue';

let db = null;

export const initDB = () => {
  return new Promise((resolve, reject) => {
    if (db) {
      resolve(db);
      return;
    }

    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
      console.error('Database error:', event.target.error);
      reject('Error opening database');
    };

    request.onsuccess = (event) => {
      db = event.target.result;
      resolve(db);
    };

    request.onupgradeneeded = (event) => {
      const database = event.target.result;

      // Create expenses store
      if (!database.objectStoreNames.contains(EXPENSE_STORE)) {
        const expenseStore = database.createObjectStore(EXPENSE_STORE, {
          keyPath: 'id',
          autoIncrement: true
        });
        expenseStore.createIndex('date', 'date');
        expenseStore.createIndex('syncStatus', 'syncStatus');
        expenseStore.createIndex('serverId', 'serverId', { unique: false });
      }

      // Create sync queue store
      if (!database.objectStoreNames.contains(SYNC_QUEUE_STORE)) {
        const syncQueueStore = database.createObjectStore(SYNC_QUEUE_STORE, {
          keyPath: 'id'
        });
        syncQueueStore.createIndex('timestamp', 'timestamp');
      }
    };
  });
};

// Expense CRUD operations
export const addExpense = async (expense) => {
  const database = await initDB();
  return new Promise((resolve, reject) => {
    const transaction = database.transaction([EXPENSE_STORE], 'readwrite');
    const store = transaction.objectStore(EXPENSE_STORE);

    const request = store.add({
      ...expense,
      syncStatus: expense.syncStatus || 'pending',
      timestamp: new Date().toISOString()
    });

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

export const updateExpense = async (expense) => {
  const database = await initDB();
  return new Promise((resolve, reject) => {
    const transaction = database.transaction([EXPENSE_STORE], 'readwrite');
    const store = transaction.objectStore(EXPENSE_STORE);

    const request = store.put({
      ...expense,
      timestamp: new Date().toISOString()
    });

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

export const getExpense = async (id) => {
  const database = await initDB();
  return new Promise((resolve, reject) => {
    const transaction = database.transaction([EXPENSE_STORE], 'readonly');
    const store = transaction.objectStore(EXPENSE_STORE);
    const request = store.get(id);

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

export const getExpenses = async () => {
  const database = await initDB();
  return new Promise((resolve, reject) => {
    const transaction = database.transaction([EXPENSE_STORE], 'readonly');
    const store = transaction.objectStore(EXPENSE_STORE);
    const request = store.getAll();

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

export const deleteExpense = async (id) => {
  const database = await initDB();
  return new Promise((resolve, reject) => {
    const transaction = database.transaction([EXPENSE_STORE], 'readwrite');
    const store = transaction.objectStore(EXPENSE_STORE);
    const request = store.delete(id);

    request.onsuccess = () => resolve();
    request.onerror = () => reject(request.error);
  });
};

// Sync queue operations
export const addToSyncQueue = async (expense) => {
  const database = await initDB();
  return new Promise((resolve, reject) => {
    const transaction = database.transaction([SYNC_QUEUE_STORE], 'readwrite');
    const store = transaction.objectStore(SYNC_QUEUE_STORE);

    const request = store.add({
      ...expense,
      timestamp: new Date().toISOString()
    });

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => {
        reject(request.error);
    }
  });
};

export const getSyncQueue = async () => {
  const database = await initDB();
  return new Promise((resolve, reject) => {
    const transaction = database.transaction([SYNC_QUEUE_STORE], 'readonly');
    const store = transaction.objectStore(SYNC_QUEUE_STORE);
    const request = store.getAll();

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

export const removeFromSyncQueue = async (id) => {
  const database = await initDB();
  return new Promise((resolve, reject) => {
    const transaction = database.transaction([SYNC_QUEUE_STORE], 'readwrite');
    const store = transaction.objectStore(SYNC_QUEUE_STORE);
    const request = store.delete(id);

    request.onsuccess = () => resolve();
    request.onerror = () => reject(request.error);
  });
};

export const clearSyncQueue = async () => {
  const database = await initDB();
  return new Promise((resolve, reject) => {
    const transaction = database.transaction([SYNC_QUEUE_STORE], 'readwrite');
    const store = transaction.objectStore(SYNC_QUEUE_STORE);
    const request = store.clear();

    request.onsuccess = () => resolve();
    request.onerror = () => reject(request.error);
  });
};

// Sync status operations
export const markExpenseAsSynced = async (localId, serverId) => {
    const database = await initDB();
    
    return new Promise((resolve, reject) => {
      const transaction = database.transaction([EXPENSE_STORE], 'readwrite');
      const store = transaction.objectStore(EXPENSE_STORE);
  
      // Get the existing expense
      const getRequest = store.get(localId);
  
      getRequest.onsuccess = (event) => {
        const expense = event.target.result;
        console.log('expense', expense);
        console.log(localId, serverId);
  
        if (!expense) {
          reject(new Error('Expense not found'));
          return;
        }
  
        // Update the expense with sync information
        const updatedExpense = {
          ...expense,
          syncStatus: 'synced',
          serverId: serverId,
          lastSynced: new Date().toISOString(),
        };
  
        // Put the updated expense back in the store
        const putRequest = store.put(updatedExpense);
  
        putRequest.onsuccess = () => resolve(updatedExpense);
        putRequest.onerror = () => reject(putRequest.error);
      };
  
      getRequest.onerror = (event) => {
        reject(event.target.error);
      };
  
      transaction.oncomplete = () => {
        console.log('Transaction completed.');
      };
  
      transaction.onerror = (event) => {
        reject(event.target.error);
      };
    });
  };

// Utility functions
export const getPendingSyncCount = async () => {
  const database = await initDB();
  return new Promise((resolve, reject) => {
    const transaction = database.transaction([EXPENSE_STORE], 'readonly');
    const store = transaction.objectStore(EXPENSE_STORE);
    const index = store.index('syncStatus');
    const request = index.count('pending');

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

export const getExpensesByServerId = async (serverId) => {
  const database = await initDB();
  return new Promise((resolve, reject) => {
    const transaction = database.transaction([EXPENSE_STORE], 'readonly');
    const store = transaction.objectStore(EXPENSE_STORE);
    const index = store.index('serverId');
    const request = index.get(serverId);

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

// Database cleanup
export const deleteDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.deleteDatabase(DB_NAME);

    request.onsuccess = () => {
      db = null;
      resolve();
    };

    request.onerror = () => reject(request.error);
  });
};