import React from 'react';
import styles from '../styles/SlideContent.module.scss';

const SlideContent = ({ slide }) => (
  <div className={styles.slideContent}>
    <h1 className={styles.title}>{slide.title}</h1>
    <p className={styles.description}>{slide.description}</p>
  </div>
);

export default SlideContent;