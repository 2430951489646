import React, { useState } from 'react';
import { Sun, Moon } from 'lucide-react';
import styles from '../styles/ThemeToggle.module.scss';

import { useDispatch, useSelector } from 'react-redux';
import { toggleDarkMode } from '../redux/theme/ThemeSlice.js';

const ThemeToggle = () => {

  const dispatch = useDispatch();
  const isLight = useSelector((state) => state.theme.darkMode);

  return (
    <div className={styles.toggle}>
      <button
        onClick={() => dispatch(toggleDarkMode())} 
        className={`${styles.toggle__button} ${
          !isLight ? styles['toggle__button--light'] : styles['toggle__button--dark']
        }`}
      >
        <div
          className={`${styles.toggle__thumb} ${isLight ? styles['toggle__thumb--light'] : styles['toggle__thumb--dark']}`}
        >
        </div>
        {isLight ? (
          <Sun className={`${styles.toggle__icon} ${styles['toggle__icon--light']}`} />
        ) : (
          <Moon className={`${styles.toggle__icon} ${styles['toggle__icon--dark']}`} />
        )}
      </button>
    </div>
  );
};

export default ThemeToggle;