import React, { useState, useMemo } from 'react';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts';

const timeframes = [
  { label: 'Custom', days: 0 },
  { label: '1 Week', days: 7 },
  { label: '1 Month', days: 30 },
  { label: '3 Months', days: 90 },
  { label: '6 Months', days: 180 },
  { label: '1 Year', days: 365 }
];

const ExpenseChart = ({ expenses = [], customStartDate, customEndDate, selectedTimeframe }) => {
//   const [selectedTimeframe, setSelectedTimeframe] = useState('1 Month');
//   const [customStartDate, setCustomStartDate] = useState('');
//   const [customEndDate, setCustomEndDate] = useState('');
  const [chartView, setChartView] = useState('time');

  // Filter expenses based on timeframe
  const getFilteredExpensesByTime = useMemo(() => {
    const endDate = new Date();
    let startDate;

    if (selectedTimeframe === 'Custom' && customStartDate && customEndDate) {
      startDate = new Date(customStartDate);
      const customEnd = new Date(customEndDate);
      return expenses.filter(expense => {
        const expenseDate = new Date(expense.date);
        return expenseDate >= startDate && expenseDate <= customEnd;
      });
    }

    const timeframe = timeframes.find(t => t.label === selectedTimeframe);
    if (!timeframe) return expenses;

    startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - timeframe.days);
    
    return expenses.filter(expense => {
      const expenseDate = new Date(expense.date);
      return expenseDate >= startDate && expenseDate <= endDate;
    });
  }, [expenses, selectedTimeframe, customStartDate, customEndDate]);

  // Generate appropriate date intervals based on timeframe
  const generateTimeIntervals = (startDate, endDate) => {
    const intervals = [];
    const currentDate = new Date(startDate);
    const end = new Date(endDate);
    
    // Calculate the total days between start and end
    const dayDiff = Math.ceil((end - startDate) / (1000 * 60 * 60 * 24));
    
    // Determine the appropriate interval size based on the date range
    let intervalType;
    let intervalSize;
    
    if (dayDiff <= 7) {
      intervalType = 'days';
      intervalSize = 1; // Daily intervals
    } else if (dayDiff <= 31) {
      intervalType = 'days';
      intervalSize = 3; // Every 3 days
    } else if (dayDiff <= 90) {
      intervalType = 'days';
      intervalSize = 7; // Weekly intervals
    } else if (dayDiff <= 180) {
      intervalType = 'days';
      intervalSize = 14; // Bi-weekly intervals
    } else if (dayDiff <= 365) {
      intervalType = 'months';
      intervalSize = 1; // Monthly intervals
    } else {
      intervalType = 'months';
      intervalSize = Math.ceil(dayDiff / 365) * 2; // Adjust months based on total years
    }

    // Generate intervals
    while (currentDate <= end) {
      intervals.push(new Date(currentDate));
      if (intervalType === 'months') {
        currentDate.setMonth(currentDate.getMonth() + intervalSize);
      } else {
        currentDate.setDate(currentDate.getDate() + intervalSize);
      }
    }

    // Ensure we include the end date if it's not already included
    if (intervals[intervals.length - 1] < end) {
      intervals.push(end);
    }

    return intervals;
  };

  // Calculate chart data
  const chartData = useMemo(() => {
    if (chartView === 'category') {
      const categoryData = new Map();
      getFilteredExpensesByTime.forEach(expense => {
        const amount = parseFloat(expense.amount);
        categoryData.set(expense.category, (categoryData.get(expense.category) || 0) + amount);
      });
      return {
        category: Array.from(categoryData.entries())
          .map(([category, value]) => ({ category, value }))
      };
    }

    // For time-based view
    const filteredExpenses = getFilteredExpensesByTime;
    if (filteredExpenses.length === 0) return { time: [] };

    const endDate = selectedTimeframe === 'Custom' && customEndDate ? 
      new Date(customEndDate) : new Date();
    const startDate = selectedTimeframe === 'Custom' && customStartDate ? 
      new Date(customStartDate) : 
      (() => {
        const start = new Date(endDate);
        const timeframe = timeframes.find(t => t.label === selectedTimeframe);
        if (timeframe) {
          start.setDate(endDate.getDate() - timeframe.days);
        }
        return start;
      })();

    const intervals = generateTimeIntervals(startDate, endDate);

    const timeData = new Map();
    intervals.forEach(date => {
      timeData.set(date.getTime(), 0);
    });

    filteredExpenses.forEach(expense => {
      const expenseDate = new Date(expense.date);
      const amount = parseFloat(expense.amount);
      
      const closest = intervals.reduce((prev, curr) => {
        return Math.abs(curr - expenseDate) < Math.abs(prev - expenseDate) ? curr : prev;
      });
      
      timeData.set(closest.getTime(), (timeData.get(closest.getTime()) || 0) + amount);
    });

    const formatDate = (date) => {
      const dateObj = new Date(date);
      const dayDiff = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
      
      if (dayDiff <= 7) {
        return dateObj.toLocaleDateString('en-US', { weekday: 'short' });
      } else if (dayDiff <= 90) {
        return dateObj.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
      } else {
        return dateObj.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
      }
    };

    return {
      time: Array.from(timeData.entries())
        .map(([timestamp, value]) => ({
          date: formatDate(timestamp),
          timestamp,
          value
        }))
        .sort((a, b) => a.timestamp - b.timestamp)
    };
  }, [getFilteredExpensesByTime, chartView, selectedTimeframe, customStartDate, customEndDate]);

  return (
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={chartView === 'time' ? chartData.time : chartData.category}>
          <defs>
            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis 
            dataKey={chartView === 'time' ? 'date' : 'category'} 
            axisLine={false} 
            tickLine={false}
            tick={{ fontSize: 12 }}
            interval="preserveStartEnd"
            angle={chartView === 'time' ? -45 : 0}
            textAnchor="end"
            height={60}
          />
          {/* <YAxis 
            axisLine={false} 
            tickLine={false}
            tick={{ fontSize: 12 }}
            tickFormatter={value => `$${value.toLocaleString()}`}
          /> */}
          
          <Tooltip
            formatter={value => [`$${value.toLocaleString()}`, 'Amount']}
            labelFormatter={label => label}
          />
          <Area
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#colorValue)"
          />
        </AreaChart>
      </ResponsiveContainer>
  );
};

export default ExpenseChart;