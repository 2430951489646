import React from 'react';
// import styles from './LegalPages.module.scss';

const Policy = () => {
  return (
    <div>
      <section>
        <h2>1. Introduction</h2>
        <p>
          Welcome to our application. This Privacy Policy outlines the data we collect from you, how it is used, and your rights regarding your data. By using the application, you consent to the data practices described in this policy. If you do not agree with this policy, please do not use the application.
        </p>
      </section>

      <section>
        <h2>2. Data Collection</h2>
        <p>We collect only the minimum information necessary to provide you with our service:</p>
        <ul>
          <li>Email address (for authentication)</li>
          <li>Basic profile information (username)</li>
          <li>Information you choose to provide within the application</li>
        </ul>
      </section>

      <section>
        <h2>3. Use of Data</h2>
        <p>Your data is used exclusively within the application to:</p>
        <ul>
          <li>Provide and maintain the service</li>
          <li>Authenticate your account</li>
          <li>Enable features and functionality you request</li>
        </ul>
      </section>

      <section>
        <h2>4. Data Protection</h2>
        <p>We implement industry-standard security measures to protect your data:</p>
        <ul>
          <li>Encrypted data transmission (HTTPS)</li>
          <li>Password hashing</li>
          <li>Database security best practices</li>
        </ul>
      </section>

      <section>
        <h2>5. Third-Party Access</h2>
        <p>We do not share your personal data with any third parties. Your information remains within the application and is used solely for providing the service.</p>
      </section>

      <section>
        <h2>6. Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access your personal data</li>
          <li>Request correction of your data</li>
          <li>Request deletion of your data</li>
          <li>Export your data</li>
        </ul>
      </section>

      <section>
        <h2>7. Contact Information</h2>
        <p>For any privacy-related questions or concerns, please contact us through the application's support channels.</p>
      </section>
    </div>
  );
};

export default Policy;
